import * as React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useInView } from "react-intersection-observer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Rooms from "../components/rooms"
import Facilities from "../components/facilities"
import Extra from "../components/extra"
import Store, { Nav } from "../store"
import "./app.css"
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next"

interface DataProps {}

const IndexPage: React.FC<DataProps> = () => {
  const { t } = useTranslation()
  const { language, originalPath } = useI18next()

  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "DSC05593.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1366, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      welcome: file(relativePath: { eq: "DJI_0476.jpg" }) {
        childImageSharp {
          fixed(height: 300, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [LazyComponents, setLazyComponents] = React.useState<{
    Map: React.FC<any> | null
    Gallery: React.FC<any> | null
  }>({
    Map: null,
    Gallery: null,
  })

  const scrollListener = () => {
    if (window.scrollY > 5 && !LazyComponents.Map) {
      Promise.all([
        import("../components/gallery"),
        import(`../components/fold`),
      ]).then(([gallery, fold]) => {
        setLazyComponents({ Map: fold.default, Gallery: gallery.default })
      })
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener, { passive: true })
    scrollListener()
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [])

  const [activeNav, setActiveNav] = React.useState<Nav>("")

  const [refMain, inViewMain, entryMain] = useInView({
    threshold: 0,
  })

  const [refRooms, inViewRooms, entryRooms] = useInView({
    threshold: 0.1,
  })
  const [refFacilities, inViewFacilities, entryFacilities] = useInView({
    threshold: 0.1,
  })
  const [refGallery, inViewGallery, entryGallery] = useInView({
    threshold: 0.1,
  })
  const [refExtra, inViewExtra, entryExtra] = useInView({
    threshold: 0.1,
  })
  const [refContact, inViewContact, entryContact] = useInView({
    threshold: 0.1,
  })

  React.useEffect(() => {
    if (inViewMain) {
      window.history.replaceState("", "", "#")
      setActiveNav("")
    } else if (inViewRooms) {
      window.history.replaceState("", "", `#szobak`)
      setActiveNav("szobak")
    } else if (inViewFacilities) {
      window.history.replaceState("", "", `#szolgaltatasok`)
      setActiveNav("szolgaltatasok")
    } else if (inViewGallery) {
      window.history.replaceState("", "", `#galeria`)
      setActiveNav("galeria")
    } else if (inViewExtra) {
      window.history.replaceState("", "", `#borravalo`)
      setActiveNav("borravalo")
    } else if (inViewContact) {
      window.history.replaceState("", "", `#kapcsolat`)
      setActiveNav("kapcsolat")
    } else {
      setActiveNav("")
    }
  }, [
    inViewMain,
    inViewRooms,
    inViewFacilities,
    inViewGallery,
    inViewExtra,
    inViewContact,
  ])

  return (
    <Store.Provider
      value={{
        activeNav,
        setActiveNav,
        sectionMain: entryMain?.target,
        sectionRooms: entryRooms?.target,
        sectionFacilities: entryFacilities?.target,
        sectionGallery: entryGallery?.target,
        sectionExtra: entryExtra?.target,
        sectionContact: entryContact?.target,
      }}
    >
      <Layout>
        <>
          <SEO
            title={t("GLOBAL.TITLE")}
            metaDescription={t("GLOBAL.DESCRIPTION")}
            aria-hidden
          />
          <section className="hero" ref={refMain} aria-hidden>
            <Img
              className="fluid"
              fluid={data.mainImage.childImageSharp.fluid}
            />
          </section>
          <section className="section" aria-labelledby="welcome">
            <h2 id="welcome">{t("WELCOME.TITLE")}</h2>

            <Link to={originalPath} language={language === "hu" ? "en" : "hu"}>
              {t("WELCOME.LANGUAGE_SWITCH")}
            </Link>

            <p>{t("WELCOME.INTRO_1")}</p>
            <Img
              fixed={data.welcome.childImageSharp.fixed}
              className="welcome-illustration"
              aria-labelledby="welcome"
            />
            <p>{t("WELCOME.INTRO_2")}</p>

            <p>{t("WELCOME.INTRO_3")}</p>
          </section>

          <section className="section" aria-labelledby="rooms-title">
            <h2 id="rooms-title" tabIndex={0} ref={refRooms}>
              {t("ROOMS.TITLE")}
            </h2>
            <Rooms isReady={!!LazyComponents.Gallery} />
          </section>
          <section className="section" aria-labelledby="facilities-title">
            <h2 id="facilities-title" tabIndex={0} ref={refFacilities}>
              {t("FACILITIES.TITLE")}
            </h2>
            <Facilities />
          </section>
          <section className="section" aria-hidden>
            <h2 ref={refGallery}>{t("GALLERY.TITLE")}</h2>
            {!LazyComponents.Gallery ? (
              <div style={{ height: "712px" }}>{t("GLOBAL.LOADING")}</div>
            ) : (
              <LazyComponents.Gallery />
            )}
          </section>
          <section className="section" aria-labelledby="extra-title">
            <h2 id="extra-title" tabIndex={0} ref={refExtra}>
              {t("EXTRA.TITLE")}
            </h2>
            <Extra />
          </section>

          <section className="section" aria-labelledby="contact-title">
            <h2 id="contact-title" tabIndex={0} ref={refContact}>
              {t("CONTACT.TITLE")}
            </h2>
            {!LazyComponents.Map ? (
              t("GLOBAL.LOADING")
            ) : (
              <LazyComponents.Map refContact={refContact} />
            )}
          </section>
        </>
      </Layout>
    </Store.Provider>
  )
}

export default IndexPage
